import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./App.css";
import ConfigForm from "./ConfigForm";
import ConfigContent from "./ConfigContent";
import * as Constants from "./constants";
import {
  hasValidToken,
  redirectToLogin,
  isEmployee,
  isUnauthorized,
  redirectToInvalidateToken,
  redirectToHome,
  redirectToLogout,
  redirectToCognitoLogout,
} from "./utilities";

function App() {
  const [jnumber, setJnumber] = useState([]);
  const [timestamp, setTimestamp] = useState([]);
  const [configFileData, setConfigFileData] = useState([]);
  const [statusMessage, setStatusMessage] = useState([]);
  useEffect(() => {}, [configFileData]); // <--- dependencies, anytime any of these changes, do this function.

  function updateData(fileData, timestamp, jnumber) {
    setJnumber(jnumber);
    setTimestamp(timestamp);
    if (fileData.length > 0) {
        fileData.unshift({"": ""})
    }
    setConfigFileData(fileData);
  }

  function updateStatus(statusMessage) {
    setStatusMessage(statusMessage);
  }

  function getAuthTokenResp(clientId, redirectUri, code) {
    const url = Constants.AUTH_TOKEN_URL;
    const http = new XMLHttpRequest();
    const body = new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      client_id: clientId,
      redirect_uri: redirectUri,
    });
    http.open("POST", url);
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    http.send(body);
    return http;
  }

  function obtainToken() {
    // Invoked if auth-token is not already present / is expired
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get(Constants.AUTH_QUERY_PARAM_KEY);
    if (code != null) {
      const clientId = Constants.COGNITO_CLIENT_ID;
      const redirectUri = Constants.APP_URL;

      // Get auth token
      const resp = getAuthTokenResp(clientId, redirectUri, code);
      resp.onload = (e) => {
        if (resp.status === 200) {
          const idToken = JSON.parse(resp.responseText)["id_token"];
          const expiration = JSON.parse(resp.responseText)["expires_in"];
          if (isEmployee(idToken)) {
            // Set valid auth token
            Cookies.remove(Constants.INVALID_TOKEN_COOKIE_NAME);
            Cookies.set(Constants.AUTH_TOKEN_COOKIE_NAME, idToken, {
              expires: expiration / 86400, // 86400 = 1 day in seconds
            });
            redirectToCognitoLogout();
          } else {
            // Set invalid auth token
            redirectToInvalidateToken();
          }
        } else {
          redirectToLogin();
        }
      };
    } else {
      redirectToLogin();
    }
  }

  if (isUnauthorized()) {
    /* The invalid token cookie is present */
    if (Cookies.get(Constants.INVALID_TOKEN_COOKIE_NAME) === "true") {
      redirectToHome();
    } else {
      Cookies.set(Constants.INVALID_TOKEN_COOKIE_NAME, true, {
        expires: 60 / 86400, // 86400 = 1 day in seconds
      });
      redirectToCognitoLogout();
    }
    return <>Unauthorized</>;
  } else if (hasValidToken()) {
    /* Checks if a valid unexpired token exists */
    return (
      <div className="App">
        <div className="App-header">
          <img src={require("./assets/Ortho-logo-white.png")} />
          <div className="header-links">
            <a href={Constants.HOME_URL}>
              Home
            </a>
            <a href={Constants.LOGOUT_URL}>
              Logout
            </a>
          </div>
        </div>

        <div className="App-content">
          <div className="form-container">
            <ConfigForm
              onFileSelect={updateData}
              onStatusUpdate={updateStatus}
            />
          </div>
          <div className="table-container">
            <ConfigContent
              configFileData={configFileData}
              timestamp={timestamp}
              jnumber={jnumber}
              statusMessage={statusMessage}
            />
          </div>
        </div>
      </div>
    );
  } else {
    /* If no valid token exists, the page redirects so you can obtain one */
    setTimeout(() => {
      obtainToken();
    }, 2000);
    return (
      <>
        You will be automatically redirected or{" "}
        <a href="#" onClick={redirectToLogin}>
          click here to login
        </a>
        .
      </>
    );
  }
}

export default App;

import * as Constants from "./constants";
import Cookies from "js-cookie";

const JWT = require("jsonwebtoken");

/**
 * Will return True if the auth_token cookie is present and not expired.
 */
export function hasValidToken() {
  const token = Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME);
  if (token !== undefined) {
    const decodedToken = JWT.decode(token);
    const dateNow = parseInt(Date.now().toString().substr(0, 10));
    if (decodedToken.exp > dateNow) return true;
    console.log("Token has expired");
    Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  }
  return false;
}

export function isEmployee(idToken) {
  if (idToken !== undefined) {
    const decodedToken = JWT.decode(idToken);
    if (decodedToken["custom:UserType"] === "E") return true;
  }
  return false;
}

export function isUnauthorized() {
  const cookie = Cookies.get(Constants.INVALID_TOKEN_COOKIE_NAME);
  return cookie !== undefined;
}

/**
 * Refreshes the app page.
 */
export function refreshPage() {
  window.location.href = Constants.APP_URL;
}

/**
 * Redirects the page to the Ortho login screen.
 */
export function redirectToLogin() {
  window.location.href = Constants.LOGIN_URL;
}

export function redirectToLogout() {
  window.location.href = Constants.LOGOUT_URL;
}

export function redirectToInvalidateToken() {
  Cookies.remove(Constants.AUTH_TOKEN_COOKIE_NAME);
  Cookies.set(Constants.INVALID_TOKEN_COOKIE_NAME, false, {
    expires: 60 / 86400, // 86400 = 1 day in seconds
  });
  refreshPage();
}

export function redirectToHome() {
  window.location.href = Constants.HOME_URL;
}

export function redirectToCognitoLogout() {
    window.location.href = Constants.COGNITO_LOGOUT_URL;
  }

/**
 * Makes an authenticated call to the api endpoint specified.
 *
 * Returns: An XMLHttpRequest on success. Otherwise undefined (will redirect).
 */
export function callApi(endpoint, data, headers = null, method = "POST") {
  if (hasValidToken()) {
    const url = Constants.CONFIG_API_URL + endpoint;
    const http = new XMLHttpRequest();
    http.open(method, url);
    http.setRequestHeader(
      "Authorization",
      "Bearer " + Cookies.get(Constants.AUTH_TOKEN_COOKIE_NAME)
    );
    for (const key of Object.keys(headers)) {
      http.setRequestHeader(key, headers[key]);
    }
    http.send(JSON.stringify(data));
    return http;
  } else {
    redirectToLogin();
  }
}

export default {
  hasValidToken,
  refreshPage,
  redirectToLogin,
  redirectToLogout,
  callApi,
  isEmployee,
  isUnauthorized,
  redirectToInvalidateToken,
  redirectToHome,
  redirectToCognitoLogout
};

import React from "react";
import "./ConfigForm.css";
import { callApi } from "./utilities";

const defaultState = {
  jnumber: "",
  day: null,
  month: null,
  year: null,
  validTimestamps: [],
  validConfigs: [],
  useRecent: true,
};

class ConfigForm extends React.Component {
  constructor(props, config) {
    super(props);
    this.state = defaultState;

    this.getValidTimestamps = this.getValidTimestamps.bind(this);
    this.getValidConfigs = this.getValidConfigs.bind(this);
    this.toggleUseRecent = this.toggleUseRecent.bind(this);
  }

  getValidTimestamps(event) {
    event.preventDefault(); // Prevents page refresh
    this.props.onStatusUpdate("Loading..."); // Set loading message
    this.props.onFileSelect([], null, null); // <--- Pass configData timestamp and jnumber as a parameter here

    const jnumber = document.getElementById("input-jnumber").value;
    const dateString = document.getElementById("input-date").value;

    // Clear previous timestamps
    let clearState = this.state;
    clearState.validTimestamps = defaultState.validTimestamps;
    this.setState(clearState);

    if (jnumber === "") {
      this.props.onStatusUpdate("Serial number is required.");
      document.getElementById("input-jnumber").focus();
    } else if (dateString === "" && !this.state.useRecent) {
      this.props.onStatusUpdate("File date is required.");
      document.getElementById("input-date").focus();
    } else {
      // Prepare api call body
      let data = {
        jnumber: jnumber,
      };
      let date = [null, null, null];
      if (!this.state.useRecent) {
        date = dateString.split("-"); // [year, month, day]
        data = {
          jnumber: jnumber,
          year: parseInt(date[0]),
          month: parseInt(date[1]),
          day: parseInt(date[2]),
        };
      }

      // Make call to retrieve timestamps
      const headers = {
        "Content-Type": "application/json",
      };
      const http = callApi("/list", data, headers);
      http.onload = (e) => {
        if (http.status === 200) {
          this.props.onStatusUpdate(""); // Reset status message
          // Handle success
          const responseText = http.responseText;
          // Removes the [ ] in string then split by ", " and remove the extra single quotes
          const timestamps = responseText
            .substr(1, responseText.length - 2)
            .replace(/'/g, ""); // First and last character are removed '[' and ']'
          if (timestamps.length > 0) {
            // Save state
            const newState = {
              jnumber: jnumber,
              year: date[0],
              month: date[1],
              day: date[2],
              validTimestamps: timestamps.split(", "), // Convert string to array
            };
            this.setState(newState);

            // Auto select the first config
            setTimeout(() => {
              const timestampSelect = document.getElementById(
                "select-timestamp"
              );
              timestampSelect.selectedIndex = "0";
              document.getElementById("submit-step2").click();
            }, 10); // A slight delay is needed to process the timestamp selection. 10ms is plenty.
          } else {
            // Handle empty
            if (this.state.useRecent) {
              this.props.onStatusUpdate(
                "No recent timestamps found. Please select a date manually."
              );
            } else {
              this.props.onStatusUpdate(
                "No timestamps found for " + jnumber + " on " + dateString + "."
              );
            }
          }
        } else if (http.status === 404) {
          // Handle not found
          this.props.onStatusUpdate("No timestamps exist for " + jnumber + ".");
        } else {
          // Handle unknown error
          this.props.onStatusUpdate(
            "Failed to retrieve timestamps from server."
          );
        }
      };
    }
  }

  getValidConfigs(event) {
    event.preventDefault(); // Prevents page refresh
    this.props.onStatusUpdate("Loading..."); // Set loading message
    this.props.onFileSelect({}, null, null); // Reset config data on submit
    const timestamp = document.getElementById("select-timestamp").value;

    if (timestamp !== "" && timestamp !== null) {
      document.getElementById("submit-step2").disabled = true;
      // Make call to retrieve configuration files
      const data = {
        jnumber: this.state.jnumber,
        time_stamp: timestamp,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      const http = callApi("/view", data, headers);
      http.onload = (e) => {
        if (http.status === 200) {
          this.props.onStatusUpdate(""); // Reset status message
          document.getElementById("submit-step2").disabled = false;
          // Handle success
          const configData = JSON.parse(
            http.responseText.replace("'", '"').replace("'", '"')
          );

          // Pass data
          this.props.onFileSelect(configData, timestamp, this.state.jnumber); // <--- Pass configData timestamp and jnumber as a parameter here
        } else {
          document.getElementById("submit-step2").disabled = false;
          // Handle error
          this.props.onFileSelect([], null, null); // Reset config data on error
          this.props.onStatusUpdate(
            "No configuration data is present for the given timestamp."
          );
        }
      };
    }
  }

  toggleUseRecent(event) {
    let newState = this.state;
    newState.useRecent = !this.state.useRecent;
    this.setState(newState);
  }

  render() {
    return (
      <div className="ConfigForm">
        <form className="form-step" onSubmit={this.getValidTimestamps}>
          <div className="form-field">
            <label>Serial Number</label>
            <input
              type="text"
              id="input-jnumber"
              name="jnumber"
              placeholder="ex: 34500009"
            />
          </div>

          <div className="form-field-date">
            <label>File Date</label>
            <input
              id="input-date"
              type="date"
              disabled={this.state.useRecent}
            ></input>
            <div className="checkbox">
              <input
                id="cb-recent"
                type="checkbox"
                onClick={this.toggleUseRecent}
                defaultChecked={this.state.useRecent}
              ></input>
              <label>Use most recent</label>
            </div>
          </div>

          <button type="submit" className="form-submit" id="submit-step1">
            Submit
          </button>
        </form>

        <form className="form-step" onSubmit={this.getValidTimestamps}>
          <div className="form-field">
            <label>Timestamp</label>
            <select
              id="select-timestamp"
              disabled={
                this.state.validTimestamps === undefined ||
                this.state.validTimestamps.length === 0
              }
            >
              {this.state.validTimestamps.map((timestamp) => (
                <option key={timestamp} value={timestamp}>
                  {timestamp}
                </option>
              ))}
            </select>
          </div>
          <button
            type="button"
            id="submit-step2"
            onClick={this.getValidConfigs}
            className="form-submit"
            disabled={
              this.state.validTimestamps === undefined ||
              this.state.validTimestamps.length === 0
            }
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default ConfigForm;

import React, { useState, useEffect } from "react";
import "./ConfigContent.css";
import ConfigTable from "./ConfigTable";
import { callApi } from "./utilities";

function ConfigContent({ configFileData, timestamp, jnumber, statusMessage }) {
  // States
  let [selectedConfig, setSelectedConfig] = useState("");
  let [attributes, setAttributes] = useState([]);
  let [filteredData, setFilteredData] = useState([]);

  // Effects
  useEffect(() => {}, [selectedConfig]); // <--- dependencies, anytime any of these changes, do this function.

  // Functions
  function selectConfig(event) {
    const configName = event.target.value;
    setSelectedConfig(configName);
    if (configName === "") {
        setAttributes([]);
        setFilteredData([]);
    } else {
      // Set attributes
      const attrs = Object.keys(configFileMap[configName][0]);
      setAttributes(attrs);
      // Set initial data
      const initData = configFileMap[configName];
      setFilteredData(initData);
    }
    // Reset filter
    document.getElementById("input-filter").value = null;
  }

  function selectFilter(event) {
    // Reset filter
    document.getElementById("input-filter").value = null;
  }

  function downloadConfigFile() {
    // Call config/download
    const data = {
      jnumber: jnumber,
      time_stamp: timestamp,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const http = callApi("/download", data, headers);
    http.onload = (e) => {
      if (http.status === 200) {
        // Handle
        const csvData = http.responseText;
        const contentType = "text/csv;charset=utf-8;";
        const csvBlob = new Blob([csvData], {type: contentType});
        const filename = jnumber + "-" + timestamp + ".csv";
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(csvBlob, filename);
        }
        else {
          const a = document.createElement("a");
          a.download = filename;
          a.href = window.URL.createObjectURL(csvBlob);
          a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } else {
        // Handle error
        alert("Error: Could not retrieve configuration file stream.");
      }
    };
  }

  function submitFilter(event) {
    event.preventDefault(); // Prevents page refresh
    const value = document.getElementById("input-filter").value;
    const attr = document.getElementById("select-attribute").value;
    const config = document.getElementById("select-config").value;

    const dataCopy = [...configFileMap[config]].filter((row) => {
      return row[attr].toLowerCase().includes(value.toLowerCase());
    });

    setFilteredData(dataCopy);
  }

  // Initial data cleaning
  const configFileNames = [];
  const configFileMap = {};

  for (const i in configFileData) {
    const name = Object.keys(configFileData[i])[0];
    configFileNames.push(name);
    configFileMap[name] = configFileData[i][name];
  }

  if (configFileNames && configFileNames.length > 0) {
    return (
      <div className="ConfigContent">
        <form className="table-title" id="form-table" onSubmit={submitFilter}>
          <div>
            <label>Configuration File</label>
            <select
              id="select-config"
              onChange={selectConfig}
              value={selectedConfig}
            >
              {configFileNames.map((file) => (
                <option key={file} value={file}>
                  {file}
                </option>
              ))}
            </select>
          </div>

          <div className="table-filters">
            <div>
            <label>Filter By</label>
            <select
              id="select-attribute"
              onChange={selectFilter}
              disabled={attributes !== undefined && attributes.length <= 0}
            >
              {attributes.map((attr) => (
                <option key={attr} value={attr}>
                  {attr}
                </option>
              ))}
            </select>
            </div>
            <div>
            <label>Filter Value</label>
            <input
              type="text"
              id="input-filter"
              placeholder="Enter filter value"
              disabled={attributes !== undefined && attributes.length <= 0}
            ></input>
            </div>
            <button
              type="submit"
              disabled={attributes !== undefined && attributes.length <= 0}
            >
              Search
            </button>
          </div>
        </form>
        <ConfigTable
          selectedData={filteredData}
          attributes={attributes}
          className="table-container"
          statusMessage={statusMessage}
        ></ConfigTable>
        <div className="table-bottom">
          <div className="info">
            <p>Results: {filteredData.length}</p>
            <p>Columns: {attributes.length}</p>
          </div>
          <button type="button" onClick={downloadConfigFile}>
            Download All
          </button>
        </div>
      </div>
    );
  } else {
    return <b style={{ color: "red" }}>{statusMessage}</b>;
  }
}

export default ConfigContent;

import React from "react";
import Table from "react-bootstrap/Table";
import "./ConfigTable.css";
import uuidv4 from "uuid/dist/v4";

export default function ConfigTable({
  selectedData,
  attributes,
  statusMessage,
}) {
  if (attributes && attributes.length > 0) {
    return (
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            {attributes.map((el) => (
              <th key={uuidv4()}>{el}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(selectedData).map((i) => (
            <tr key={uuidv4()}>
              {Object.values(selectedData[i]).map((val) => (
                <td key={uuidv4()}>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  } else {
    return (
      <div className="table-message-box">
        <b style={{ color: "red"}}>
          Please select a configuration file.
        </b>
      </div>
    );
  }
}
